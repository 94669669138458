/* unplugin-vue-components disabled */import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/BehandlerChip.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/BehandlerChip.vue';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[_c(VAutocomplete,{attrs:{"items":_vm.behandlerList,"small-chips":"","solo":"","multiple":"","clearable":"","hide-details":"","hide-selected":"","dense":"","deletable-chips":"","label":_vm.label,"item-text":"displayText","item-value":"extid"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(__unplugin_components_0,{attrs:{"behandler":item,"on":on,"attrs":attrs}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(__unplugin_components_1,{attrs:{"behandler":item,"is-deletable":true,"on":on,"attrs":attrs},on:{"deleteBehandler":function($event){return _vm.remove(item)}}})]}}]),model:{value:(_vm.behandlerSelection),callback:function ($$v) {_vm.behandlerSelection=$$v},expression:"behandlerSelection"}}),_c('div',{staticClass:"pl-4"},[_c(VBtn,{attrs:{"small":"","color":"primary","disabled":_vm.disabled || _vm.behandlerSelection.length === 0},on:{"click":_vm.save}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }