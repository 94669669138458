"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsBehandlerTeamsAutoTeamsColumnsDefs = void 0;
function settingsBehandlerTeamsAutoTeamsColumnsDefs() {
    return [
        {
            headerName: 'Name',
            field: 'name',
            valueGetter: params => {
                var _a;
                return [
                    {
                        value: true,
                        title: (_a = params.data) === null || _a === void 0 ? void 0 : _a.name,
                        icon: 'user-friends',
                        showTitle: true,
                    },
                ];
            },
            cellRenderer: 'IconsCell',
            width: 180,
        },
        {
            headerName: '# Mitglieder',
            valueGetter: params => { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a.mitglieder.length; },
            cellStyle: { justifyContent: 'center' },
            sort: 'desc',
            width: 100,
        },
        {
            headerName: 'Mitglieder',
            cellRenderer: 'TeamMitgliederCell',
            autoHeight: true,
            flex: 1,
        },
    ];
}
exports.settingsBehandlerTeamsAutoTeamsColumnsDefs = settingsBehandlerTeamsAutoTeamsColumnsDefs;
