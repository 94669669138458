"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsBehandlerTeamsCustomTeamsColumnsDefs = void 0;
const types_1 = require("@rose/types");
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
const lodash_1 = require("lodash");
function settingsBehandlerTeamsCustomTeamsColumnsDefs() {
    return [
        {
            headerName: 'Name',
            field: 'name',
            sort: 'desc',
            valueGetter: params => {
                var _a, _b, _c;
                return [
                    {
                        value: true,
                        title: (_a = params.data) === null || _a === void 0 ? void 0 : _a.name,
                        teamId: (_b = params.data) === null || _b === void 0 ? void 0 : _b.id,
                        icon: 'user-friends',
                        showTitle: true,
                        debugInfo: `(${(0, lodash_1.join)((_c = params.data) === null || _c === void 0 ? void 0 : _c.mitglieder.map(m => `'${m}'`), ', ')})`,
                    },
                ];
            },
            cellRenderer: 'IconsCell',
            cellRendererParams: {
                context: {
                    isEditable: true,
                    itemChanged: (item) => {
                        editSettingsStore_1.editSettingsStore.commit.updateTeamName({ teamId: item.id, name: item.title });
                    },
                },
            },
            width: 220,
        },
        {
            headerName: 'Typ',
            field: 'typ',
            sort: 'asc',
            cellRenderer: 'DropdownCell',
            cellRendererParams: {
                context: {
                    options: [
                        { text: 'Team', value: types_1.TeamTypes.TEAM },
                        { text: 'Bündel', value: types_1.TeamTypes.BUNDLE },
                    ],
                    itemChanged: (item) => {
                        editSettingsStore_1.editSettingsStore.commit.updateTeamType({ teamId: item.id, type: item.value });
                    },
                },
            },
            width: 150,
        },
        {
            headerName: 'Farbe',
            field: 'farbe',
            sort: 'asc',
            valueGetter: params => {
                var _a, _b;
                return ({
                    id: (_a = params.data) === null || _a === void 0 ? void 0 : _a.id,
                    value: (_b = params.data) === null || _b === void 0 ? void 0 : _b.farbe,
                });
            },
            cellRenderer: 'ColorPickerCell',
            cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
            width: 80,
        },
        {
            headerName: 'Mitglieder',
            cellRenderer: 'TeamMitgliederCell',
            autoHeight: true,
            flex: 1,
            cellRendererParams: {
                context: {
                    isCustom: true,
                },
            },
        },
        {
            headerName: '',
            cellRenderer: 'DeleteButtonCell',
            cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
            width: 40,
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteTeam({ teamId: field.row.id });
                    },
                },
            },
        },
        {
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 40,
        },
    ];
}
exports.settingsBehandlerTeamsCustomTeamsColumnsDefs = settingsBehandlerTeamsCustomTeamsColumnsDefs;
