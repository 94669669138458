"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsBehandlerTeamsBehandlerColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
const types_1 = require("@rose/types");
const teamsStore_1 = require("@/state/teamsStore");
const behandlerStore_1 = require("@/state/behandlerStore");
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function settingsBehandlerTeamsBehandlerColumnsDefs() {
    return [
        {
            headerName: 'Behandler',
            valueGetter: params => params.data,
            comparator: (a, b) => { var _a, _b, _c; return (_b = (_a = behandlerStore_1.behandlerStore.getters.map[a]) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.localeCompare((_c = behandlerStore_1.behandlerStore.getters.map[b]) === null || _c === void 0 ? void 0 : _c.name); },
            cellRenderer: 'BehandlerIdCell',
            sort: 'asc',
            sortable: true,
            minWidth: 250,
            resizable: true,
        },
        {
            headerName: 'Team',
            valueGetter: params => [
                {
                    value: params.data && (0, common_ui_1.getTeamMemberShips)(params.data, teamsStore_1.teamsStore.getters.teams, types_1.TeamTypes.TEAM).length,
                    title: `Mitglied in Teams: ${params.data &&
                        (0, common_ui_1.getTeamMemberShips)(params.data, teamsStore_1.teamsStore.getters.teams, types_1.TeamTypes.TEAM)
                            .map((v) => v.name)
                            .join(', ')}`,
                    icon: 'user-friends',
                },
                {
                    value: params.data && (0, common_ui_1.getTeamMemberShips)(params.data, teamsStore_1.teamsStore.getters.teams, types_1.TeamTypes.BUNDLE).length,
                    title: `Mitglied in Bündeln: ${params.data &&
                        (0, common_ui_1.getTeamMemberShips)(params.data, teamsStore_1.teamsStore.getters.teams, types_1.TeamTypes.BUNDLE)
                            .map((v) => v.name)
                            .join(', ')}`,
                    icon: 'user',
                },
            ],
            cellRenderer: 'IconsCell',
            width: 80,
            cellStyle: { justifyContent: 'center' },
        },
        {
            headerName: 'Funktion & Position',
            cellRenderer: 'ZaPzrCell',
            width: 370,
        },
        {
            headerName: 'Terminbehandler',
            field: 'terminBehandler',
            cellRenderer: 'TerminBehandlerCell',
            autoHeight: true,
            flex: 1,
        },
        {
            headerName: '',
            width: 80,
            cellRenderer: 'DeleteButtonCell',
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteBehandlerFromZuordnung([field.row]);
                    },
                },
            },
        },
        {
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 40,
        },
    ];
}
exports.settingsBehandlerTeamsBehandlerColumnsDefs = settingsBehandlerTeamsBehandlerColumnsDefs;
